// styled components
import {Menu, UserWrapper} from '../style';

// components
import Avatar from '@ui/Avatar';

// utils
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useState} from 'react';
import ShapeButton from '@ui/ShapeButton';

// assets
import doc1jpg from '@assets/avatars/doc1.jpg';
import doc1webp from '@assets/avatars/doc1.jpg?as=webp';
import { useNavigate  } from "react-router-dom";

const CurrentUser = () => {
    const [open, setOpen] = useState(false);
    const handleClickAway = () => setOpen(false);
    const handleClick = () => setOpen(!open);
    let navigate = useNavigate();


    const handleDelete = () => {
      
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
        navigate("/login");
    };


    const src = {
        jpg: doc1jpg,
        webp: doc1webp
    } 

    const user = JSON.parse(localStorage.getItem('user'));


    /*
    if (!user || !user.role || !user.doctor) {
        console.log("user.doctor.photo ")
        return null; 
      } */
    

    

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <UserWrapper>


            {user && user.doctor && user.doctor.photo ? (

                    <>
                    <Avatar photo={user.doctor.photo} avatar={src} alt="avatar"/>
                    <div className="info">
                        <span className="h3">{user.name}</span>
                        <span className="position">{user.role.name}</span>
                        <Menu className={open ? 'visible' : ''}>
                            {/*<button>
                                <i className="icon icon-circle-user" /> Change user
                            </button>*/}
                            <button onClick={handleDelete}>
                                <i className="icon icon-logout"/> Logout
                            </button>
                        </Menu>
                    </div>
                    <button className="trigger" onClick={handleClick} aria-label="Show menu">
                        <i className="icon icon-chevron-down" />
                    </button>
                    </>

            )
            
            
            
            :
            
            (
            <>    
                <div className="info">
                        <span className="h3">{user.name}</span>
                        <span className="position">{user.role.name=='Patient'?"User":user.role.name}</span>
                        <Menu className={open ? 'visible' : ''}>
                            <button onClick={handleDelete}>
                                <i className="icon icon-logout"/> Logout
                            </button>
                            {/*<button>
                                <i className="icon icon-circle-user" /> Change user
                            </button>*/}
                    
                        </Menu>
                    </div>
                    <button className="trigger" onClick={handleClick} aria-label="Show menu">
                        <i className="icon icon-chevron-down" />
                    </button>
            </>
            
            )}
              
               
            </UserWrapper>
        </ClickAwayListener>
    )
}

export default CurrentUser;