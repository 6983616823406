

export const menu = [

    {
        icon: 'blocks',
        name: 'Dashboard',
        link: '/dashboard_k'
    },
    {
        icon: 'book-open',
        name: 'Appointments',
        link: '/allAppointments'
    },
    {
        icon: 'stethoscope',
        name: 'Specialists',
        link: '/doctors'
    }, 
    {
        icon: 'users',
        name: 'Users',
        link: '/patients'
    },
    {
        cat: 'Catalogs',
        icon: 'expand-regular',
        links: [
            { name: "Specialties", link: '/specialties'},
            { name: "Subspecialties", link: '/subspecialties'},
            { name: "Services", link: '/services' },
          
        ]
    }, 
    {
        icon: 'wallet',
        name: 'Finances',
        link: '/finances'
    },
    {
        icon: 'settings',
        name: 'Settings',
        link: '/settings'
    }
 
 
]





export const menu2 = [

  
    {
        icon: 'users',
        name: 'Users',
        link: '/patients'
    },
    {
        icon: 'wallet',
        name: 'Finances',
        link: '/finances'
    },
    {
        icon: 'calendar',
        name: 'Appointments',
        link: '/dashboard_appointments'
    },
    {
        icon: 'settings',
        name: 'Settings',
        link: '/settings'
    }
 
]


export const menu1 = [


    {
        icon: 'calendar',
        name: 'Appointments',
        link: '/dashboard_appointments'
    }, 
    {
        icon: 'wallet',
        name: 'Finances',
        link: '/finances-Patients'
    },
    {
        icon: 'settings',
        name: 'Settings',
        link: '/settings'
    }
 
]




/*
export const menu = [
    {
        cat: 'dashboard',
        icon: 'blocks',
        links: [
            { name: "Dashboard A", link: '/dashboard_a'},
            { name: "Dashboard B", link: '/dashboard_b'},
            { name: "Dashboard C", link: '/dashboard_c' },
            { name: "Dashboard D", link: '/dashboard_d' },
            { name: "Dashboard E", link: '/dashboard_e' },
            { name: "Dashboard F", link: '/dashboard_f' },
            { name: "Dashboard G", link: '/dashboard_g' },
            { name: "Dashboard H", link: '/dashboard_h' },
            { name: "Dashboard I", link: '/dashboard_i' },
            { name: "Dashboard J", link: '/dashboard_j' },
            { name: "Dashboard K", link: '/dashboard_k' },
            { name: "Page 404", link: '/404' },
        ]
    },
    {
        cat: 'appointments',
        icon: 'calendar',
        links: [
            { name: "Patient Appointments", link: '/patient_appointments' },
            { name: "Doctor Appointments", link: '/doctor_appointments' }
        ]
    },
    {
        cat: 'patients',
        icon: 'users',
        links: [
            { name: "Patients", link: '/patients' },
            { name: "Tests", link: '/tests' }
        ]
    },
    {
        cat: 'doctors',
        icon: 'stethoscope',
        links: [
            { name: "Doctors", link: '/doctors' },
            { name: "Staff", link: '/staff' }
        ]
    },
    {
        cat: 'messages',
        icon: 'comment',
        links: [
            { name: "Doctor Messenger", link: '/doctor_messenger' },
            { name: "Patient Messenger", link: '/patient_messenger' }
        ]
    },
    {
        cat: 'reviews',
        icon: 'star',
        links: [
            { name: "Doctor Reviews", link: '/doctor_reviews' },
            { name: "Patient Reviews", link: '/patient_reviews' }
        ]
    },
    {
        icon: 'wallet',
        name: 'Finances',
        link: '/finances'
    },
    {
        icon: 'settings',
        name: 'Settings',
        link: '/settings'
    }
]*/