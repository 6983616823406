// styled components
import {LinksList, List, MainItem} from '../style';
import {colors} from '@styles/vars';

// components
import Accordion from 'react-bootstrap/Accordion';
import {NavLink} from 'react-router-dom';

// hooks
import {useSidebarContext} from '@contexts/sidebarContext';

// menu links
import {menu1, menu, menu2} from '@constants/menu';
import styled from 'styled-components';


const StyledLinkName = styled.span`
  font-size: 20px; 
`;

const StyledNavLink = styled.span`
  font-size: 20px; 
`;

const Content = () => {



    const { toggleSidebar } = useSidebarContext();
    const activeStyle = { color: colors.blue };
  
    // Obtén el valor del usuario del localStorage y verifica si existe y es válido.
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (!user || !user.role) {
      // Manejar el caso cuando el usuario o su propiedad 'role' no existen.
      return null; // O muestra un mensaje de error, o redirige a otra página, dependiendo de tus necesidades.
    }
  
    let MenuIndex = [];
    switch (user.role.id) {
      case 1:
        MenuIndex = menu;
        break;
      case 2:
        MenuIndex = menu1;
        break;
      case 3:
        MenuIndex = menu2;
        break;
      default:
        // Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }

    
    
    return (
        <List as={Accordion}>
            {
            MenuIndex.map((item, index) => {
                    if (item.cat) {
                        return (
                            <Accordion.Item eventKey={item.cat} key={item.cat}>
                                <MainItem as={Accordion.Header}>
                                    <StyledLinkName><i className={`icon icon-${item.icon}`}></i> {item.cat} </StyledLinkName>
                                </MainItem>
                                <Accordion.Body>
                                    <LinksList>
                                        {
                                            item.links.map(link => {
                                                return (
                                                    <li key={link.link}>
                                                        <NavLink to={link.link}
                                                                 onClick={() => toggleSidebar()}
                                                                 style={({isActive}) =>
                                                                     isActive ? activeStyle : undefined
                                                                 }
                                                        >
                                                         <StyledNavLink>  {link.name}</StyledNavLink>
                                                        </NavLink>
                                                    </li>
                                                )
                                            })
                                        }
                                    </LinksList>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    } else if (item.link) {
                        return (
                            <MainItem as={NavLink}
                                      to={item.link}
                                      onClick={() => toggleSidebar()}
                                      style={({isActive}) =>
                                          isActive ? activeStyle : undefined
                                      }
                                      key={item.link}
                                      /*</List>*className={index === menu.length - 1 ? 'pin-down' : ''}*/
                            >
                             <StyledLinkName>   <i className={`icon icon-${item.icon}`}></i>  {item.name}</StyledLinkName>
                            </MainItem>
                        )
                    } else return null
                })
            }
        </List>
    )
}

export default Content;