import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ApiService from "services/ApiService";
import { useAuth } from "contexts/auth";
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FormControl } from '@mui/material';
import Logo from '@assets/Logo-blanco.png';
import styled from 'styled-components/macro';
import newBackgroundImage from 'assets/imagenregistrodoctores2.png';
import { textSizes } from '@styles/vars'; // Importa solo textSizes desde vars.js
import { colors } from '@styles/vars'; // Importa colors desde vars.js

const Label = styled.label`
  font-size: ${textSizes['14']};
  width: fit-content;
  margin-bottom: 8px;
`;

const Img = styled.img`
  width: 150px;
  height: 50px;
  will-change: transform;
  transition: transform var(--transition);
`;

const defaultTheme = createTheme();

// Define authenticated function
const authenticated = (token) => {
  // Aquí puedes guardar el token en localStorage u otra lógica necesaria
  localStorage.setItem('authToken', token);
};

const Login = () => {
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);


  const handleClickShowPassword = () => {

    console.log("llega");
    setShowPassword((show) => !show)
  };



  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };


  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isChecked ) {
      Swal.fire({
        icon: "error",
        text: "Please accept Waiver, Terms & Conditions!",
      });
      return;
    }

    if (!isChecked2) {
      Swal.fire({
        icon: "error",
        text: "Please accept Aviso de privacidad (notice of privacy)!",
      });
      return;
    }
    setLoading(true);

    ApiService.signin(formData)
      .then((response) => {
        setLoading(false);

        const success = response.data.response;
        const data = response.data;

        if (success && data) {
          authenticated(data.token); // Usa la función authenticated aquí
          auth.signin(data.user, data.token);
        }
      })
      .catch((error) => {
        setLoading(false);

        let errorMessage = "An error occurred.";

        if (error.response) {
          if (error.response.status === 401) {
            errorMessage = error.response.data.error || "Correo electrónico y/o contraseña son incorrectos";
          } else {
            errorMessage = error.response.data.message || "Internal Server Error";
          }
        } else if (error.message) {
          errorMessage = error.message;
        }

        Swal.fire({
          icon: "error",
          text: errorMessage,
        });
      });
  };

  const handlePasswordRecoveryClick = () => {
    setShowPasswordRecovery(true);
  };

  const handlePasswordRecoveryClose = () => {
    setShowPasswordRecovery(false);
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    const { email } = formData;

    if (isEmailValid(email)) {
      setLoading(true);
      ApiService.sendEmailRecoverPassword(formData)
        .then(response => {
          let successMessage = "success";

          if (response.data && response.data.message) {
            successMessage = response.data.message;
          }

          Swal.fire({
            icon: "success",
            text: successMessage,
          });

          setLoading(false);

          setFormData({
            email: '',
          });
        })
        .catch(e => {
          console.log(e);

          let errorMessage = "An error occurred.";

          if (e.response && e.response.data && e.response.data.message) {
            errorMessage = e.response.data.message;
          }

          Swal.fire({
            icon: "error",
            text: errorMessage,
          });
          setLoading(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Invalid email",
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${newBackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <a href="https://mtatrust.com/">
              <Img src={Logo} alt="MTATrust" />
            </a>
            <Typography component="h1" variant="h5">
              {showPasswordRecovery ? 'Change Password' : 'Log in'}
            </Typography>
            <Box component="form" noValidate onSubmit={showPasswordRecovery ? handleSubmit2 : handleSubmit} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                  type="email"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                />
              </FormControl>
              {!showPasswordRecovery && (
                <FormControl fullWidth>
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    margin="normal"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                  <Box sx={{ '& button': { m: 1 }, mt: -2}}>
                      <Button 
                      size="small"
                      variant="text"
                      onClick={handleClickShowPassword}>
                        {showPassword ? "Hide" : "Show"}

                      </Button>
                  </Box>
                </FormControl>
              )}
              {!showPasswordRecovery && (
                <>
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} id="mostrar_contrasenas" />}
                  label={isChecked ? (
                    <Link href="https://www.mtatrust.com/terms" target="_blank" rel="noopener" variant="body2">
                      I accept Waiver, Terms & Conditions
                    </Link>
                  ) : (
                    <Label htmlFor="mostrar_contrasenas">
                      I accept Waiver, Terms & Conditions
                    </Label>
                  )}
                />
                <FormControlLabel
                control={<Checkbox checked={isChecked2} onChange={handleCheckboxChange2} id="mostrar_contrasenas" />}
                label={isChecked ? (
                  <Link href="https://www.mtatrust.com/terms" target="_blank" rel="noopener" variant="body2">
                   I accept Aviso de privacidad (notice of privacy)
                  </Link>
                ) : (
                  <Label htmlFor="mostrar_contrasenas">
                     I accept Aviso de privacidad (notice of privacy)
                  </Label>
                )}
              />
              </>
              )}
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {showPasswordRecovery ? 'Send email' : 'Log in'}
                </Button>
              )}
              {!showPasswordRecovery && (
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2" onClick={handlePasswordRecoveryClick}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              )}
              {showPasswordRecovery && (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handlePasswordRecoveryClose}
                >
                  Back to login
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
