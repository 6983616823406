import { createContext, useContext, useEffect, useState } from "react";
import { AUTH_TOKEN } from "redux/constants/Auth";
import ApiService from "services/ApiService";
import { Navigate, Route } from "react-router-dom";
import { DAS_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useNavigate  } from "react-router-dom";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const auth = ProviderValue();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function ProviderValue() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const signin = async (user, token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
    
  
    switch (user.role.id) {
      case 1:
        navigate("/dashboard_k");
        break;
      case 2:
          navigate("/dashboard_appointments");
      break;
      case 3:
        navigate("/patients");
      break;   
      default:
        console.log("Rol no definido");
      break;  
    }
  };

  useEffect(() => {
    
    async function loadUserFromCookies() {
      const token = localStorage.getItem(AUTH_TOKEN);

      if (token) {
        const response = await ApiService.getUser();

        if (response) {
          const { data } = response;
          setUser(data);
        }
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  return {
    user,
    loading,
    isAuthenticated: !!user,
    signin,
  };
}

export const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const user = useAuth();

  console.log("Userlog",user);
 


  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Navigate to={`/login`} />
        )
      }
    />
  );
};