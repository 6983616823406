import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import {Provider} from 'react-redux';
import Login from '@pages/Login';
import store from '@store/store'
import { AuthProvider } from 'contexts/auth';
import {InterfaceContextAPI} from '@contexts/interfaceContext';

// fonts
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fonts/icomoon/icomoon.woff'

ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <BrowserRouter>
            <InterfaceContextAPI>
            <AuthProvider>   
            <Routes>
              <Route path="/" element={<Navigate to="/login"/>}/>
              <Route path="/login" element={<Login/>}/> 
              <Route path="/*" element={<App />}/>         
             </Routes>   
             </AuthProvider>
            </InterfaceContextAPI>
        </BrowserRouter>
    </Provider>
);